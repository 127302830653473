<template>
    <div class="map-view">
        <Filter @filtered="filterAll" />
        <Panel :class="{ 'show': panelShow }" :events="events" />
        <GoogleMap api-key="AIzaSyBKds2uWBtwsGgHwYQg2rh17S0oIu1jpZs" class="map"
            style="width: 100%; height: calc(100vh - 75px)" :styles="mapStyle" :center="center" :zoom="6"
            :mapTypeControl="false">
            <MarkerCluster>
                <Marker v-for="location in locations" :key="location.state.id"
                    :options="{ position: { lat: location.latitude, lng: location.longitude } }"
                    @click="renderPanel(location.state.id)">
                    <InfoWindow @visible="showPanel()" @closeclick="hidePanel()"
                        :ref="(el) => (infoWindows[location.state.id] = el)">
                        <div class="content">
                            <h4 id="firstHeading" class="firstHeading m-0">{{ location.name }}</h4>
                            <div id="bodyContent">
                                <p><b>{{ location.case_count }} {{ $t('map.events') }}</b> {{ $t('map.recorded') }}</p>
                                <button class="btn-small">
                                    <router-link :to="'../events/' + location.state.id">{{ $t('map.details')
                                    }}</router-link>
                                </button>
                            </div>
                        </div>
                    </InfoWindow>
                </Marker>
            </MarkerCluster>
        </GoogleMap>
    </div>
</template>

<script>

import { defineComponent } from "vue";
import Filter from "./Filter.vue";
import Panel from "./Panel.vue";
import { GoogleMap, MarkerCluster, Marker, InfoWindow } from "vue3-google-map";
import moment from 'moment'


export default defineComponent({
    components: {
        Panel,
        Filter,
        GoogleMap,
        MarkerCluster,
        Marker,
        InfoWindow,
    },
    data() {
        return {
            panelShow: false,
            openMarker: null,
            events: [],
            locations: [],
            filteredGenders: [],
            filteredStates: [],
            filteredCovenants: [],
            startDate: "",
            endDate: "",
            startAge: "",
            endAge: "",
            infoWindows: []
        }
    },
    methods: {
        showPanel() {
            this.panelShow = true
        },
        hidePanel() {
            this.panelShow = false
        },
        closeInfoWindows(except) {
            this.infoWindows.forEach((ref, index) => {
                if (index !== except) ref.infoWindow.close();
            });
        },

        // Render cases to side panel
        async fetchCases(stateID) {
            let params = new URLSearchParams();
            params.append('filter[state_id]', stateID);
            const res = await fetch('https://backend.freedomstech.net/api/cases/get_cases?' + params)
            const events = await res.json()
            return events.data
        },
        async renderPanel(stateID) {
            this.closeInfoWindows(stateID)
            this.events = await this.fetchCases(stateID)
        },

        // Render filtered locations
        async fetchLocations() {
            // this.closeInfoWindows(null);
            this.infoWindows = [];
            let params = new URLSearchParams();
            params.append('filter[gender_id]', this.filteredGenders);
            params.append('filter[state_id]', this.filteredStates);
            params.append('filter[disappearance_date_from]', this.startDate);
            params.append('filter[disappearance_date_to]', this.endDate);
            params.append('filter[age_from]', this.startAge);
            params.append('filter[age_to]', this.endAge);

            const res = await fetch('https://backend.freedomstech.net/api/cases/get_summary?' + params)
            const locations = await res.json()
            return locations.data
        },

        async filterAll(genders, states, covenants, date = [], ages = []) {
            if (date == null) date = [];
            this.filteredGenders = genders
            this.filteredStates = states
            this.filteredCovenants = covenants
            this.startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : '';
            this.endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : '';
            this.startAge = ages[0];
            this.endAge = ages[1];
            this.locations = await this.fetchLocations()
        },
    },
    async created() {
        this.locations = await this.fetchLocations()
    },
    setup() {
        const center = { lat: 27.156486, lng: 30.491588 };
        const mapStyle = [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ]
        return { center, mapStyle };
    }
});
</script>

<style scoped>
.map-view {
    display: flex;
}

.map {
    margin-right: 0;
    transition: 0.3s;
    width: 100%;
}

.panel.show+.map {
    margin-right: 300px;
}

.rtl .panel.show+.map {
    margin-right: 0;
    margin-left: 300px;
}
</style>
