<template>
    <footer>
        <div class="footer">
            <div class="footer-column">
                <router-link to="/">
                    <h1>{{ $t("footer.title") }}</h1>
                </router-link>
                <p>{{ $t("footer.brief") }}</p>
            </div>
            <div class="footer-column">
                <ul>
                    <li>
                        <h5>{{ $t("mail") }}</h5>
                        <a href="mailto:info@disappearancemap.com"><b>info@dismap.org</b></a>
                    </li>
                    <li>
                        <h5>{{ $t("call") }}</h5>
                        <a class="tel" href="tel:0020123456789"><b>+20 123 456 789</b></a>
                    </li>
                </ul>
            </div>
            <div class="footer-column">
                <ul>
                    <li>
                        <h5>{{ $t("follow") }}</h5>
                    </li>
                    <li>
                        <a href="#"><font-awesome-icon icon="fa-brands fa-facebook" /><b>Facebook</b></a>
                    </li>
                    <li>
                        <a href="#"><font-awesome-icon icon="fa-brands fa-twitter" /><b>Twitter</b></a>
                    </li>
                    <li>
                        <a href="#"><font-awesome-icon icon="fa-brands fa-youtube" /><b>Youtube</b></a>
                    </li>
                    <li>
                        <a href="#"><font-awesome-icon icon="fa-brands fa-instagram" /><b>Instagram</b></a>
                    </li>
                </ul>
            </div>
            <div class="footer-column">
                <form action="">
                    <input type="email" class="form-control" :placeholder="$t('email')">
                    <button class="btn btn-main">{{ $t("subscribe") }}</button>
                </form>
            </div>
        </div>
        <div class="rights">
            <p>{{ $t("rights") }}</p>
        </div>
    </footer>
</template>

<script>
import Button from "./Button.vue";

export default {
    name: 'Footer',
    components: {
        Button,
    },
}
</script>

<style scoped>
footer {
    padding: 1rem;
    background-color: black;
    transition: 0.3s ease-in-out;
}

.footer {
    position: relative;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.footer :where(h1, h2, h3, h4, h5, h6) {
    font-size: 1.25rem;
    font-weight: bold;
    color: white !important;
    margin-bottom: 1rem;
}

.footer p {
    color: white;
    font-size: 0.8rem;
    line-height: 2;
}

.footer ul {
    gap: 1rem;
    list-style: none;
    padding: 0;
    padding-left: 0.5rem;
    margin: 0;
}

.footer ul li {
    margin-bottom: 0.5rem;
}

.footer ul li a {
    font-family: 'Aldrich', sans-serif;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin: 0;
    transition: 0.3s;
    color: white;
    font-weight: lighter;
    font-size: 0.9rem;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: 0.3s ease-in-out;
}

.footer .svg-inline--fa {
    width: 20px;
}

.footer form button {
    width: 100%;
}

.rights p {
    text-align: center;
    margin: 1.5rem 0 0;
    font-size: 0.75rem;
    color: white;
}

@media (max-width: 768px) {
    .footer {
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .footer .footer-column {
        text-align: center;
    }

    .footer ul li a {
        justify-content: center;
    }
}
</style>