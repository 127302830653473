<template>
    <div class="panel">
        <div class="event" v-for="event in events" :key="event.id">
            <router-link :to="'/event/' + event.id">
                <h5>{{ event.name }}</h5>
                <small>{{ event.disappearance_date }}</small>
                <small>{{ event.city.name }}</small>
                <b>{{ event.status }}</b>
            </router-link>
            <hr>
        </div>
    </div>
</template>

<script>

export default {
    name: "Panel",
    props: {
        events: Array
    }
}
</script>

<style scoped>
.panel {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white;
    padding: 1rem 0rem;
    width: 0px;
    transition: 0.3s;
    overflow-x: hidden;
    overflow-y: auto;
}

.rtl .panel {
    right: unset;
    left: 0;
}

.rtl .panel.show {
    background-color: white;
    padding: 1rem;
    width: 300px;
    box-shadow: 0 0 16px -10px var(--darkGrey);

}

.event a {
    position: relative;
    top: 0;
    display: block;
    padding: 1rem;
    box-shadow: 0 0 15px -5px #ddd;
    transition: 0.3s;
}

.event a:hover {
    top: -5px;
    display: block;
    padding: 1rem;
    box-shadow: 0 2px 15px -5px #ddd;
}

h5 {
    font-weight: bold;
    color: var(--mainColorDarker);
    margin: 0;
}

b {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    color: #888;
}

small {
    display: block;
    color: #bbb;
    font-size: 0.75rem;
}
</style>