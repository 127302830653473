<template>
  <div class="container-fluid p-0" v-bind:class="[{ rtl: this.rtl }]">
    <Search :class="{ 'show': showSearch }" />
    <Header @btnClick="toggleSearch()" />
    <div class="body">
      <div class="view-content">
        <!-- <SideBar /> -->
        <AddCase :class="{ 'show': showAddCase }" />
        <router-view :showAddCase="showAddCase" />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Search from "./components/Search.vue";
// import SideBar from "./components/SideBar.vue";
import AddCase from "./components/AddCase.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Search,
    // SideBar,
    AddCase,
    Footer,
  },
  data() {
    return {
      showAddCase: false,
      showSearch: false,
      rtl: localStorage.getItem('lang') == 'ar' || true
    }
  },
  beforeMount() {
    this.$i18n.locale = localStorage.getItem('lang') || 'ar';
  },
  methods: {
    toggleAddCase() {
      this.showAddCase = !this.showAddCase
    },
    toggleSearch() {
      document.querySelector('.search-wrapper').classList.add('show');
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700&display=swap');

.rtl {
  direction: rtl;
  font-family: 'Noto Kufi Arabic', sans-serif;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #F9F9F9 !important;
}

body * {
  box-sizing: border-box;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh);
}

:root {
  --mainColorDarker: #682323;
  --mainColor: #db3333;
  --mainColorLight: #50A0602b;
  --textColor: #565360;
  --darkGrey: #908e9b;
  --Grey: #F6F7F9;
  --lightGrey: #f2f5f5;
}

hr {
  border-color: #ccc !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: var(--mainColor) !important;
}

a {
  text-decoration: none !important;
}

span,
p {
  color: var(--darkGrey);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 10px;
  border: none;
  font-size: 0.8rem;
  padding: 0.45rem 1.45rem !important;
  border: 1px solid;
  border-radius: 0.15rem !important;
  cursor: pointer;
  transition: 0.2s ease-in-out !important;
}

.btn.btn-main {
  background-color: var(--mainColor) !important;
  color: white !important;
  border: none !important;
}

.btn.btn-secondary {
  background-color: black;
  color: white;
  border-color: white;
  font-size: 1rem;
}

.btn:hover {
  transform: scale(0.9);
  background-color: white !important;
  color: black !important;
}

.btn.btn-main:hover {
  background-color: var(--mainColor) !important;
  color: white !important;
}

.btn:focus {
  outline: none;
}

#app {
  font-family: 'Aldrich', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app .view-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh);
}

input,
select,
textarea {
  width: 100%;
  margin: 5px 0;
  padding: 0.5rem;
  box-sizing: border-box;
}

.btn-small {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--mainColorDarker);
  color: white !important;
  margin: 0 auto;
  border-radius: 50px;
  transition: 0.3s;
}

.btn-small a {
  color: white !important;
}

.btn-small:hover {
  background-color: var(--mainColor);
}

.select2 {
  width: 100% !important;
  margin: 5px 0;
  font-size: 0.8rem;
}

.select2 .select2-selection {
  height: auto;
  padding: 0.75rem;
  background-color: var(--Grey);
  border: none;
  border-radius: 0.5rem;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 !important;
  text-align: start;
  line-height: 1.5;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}

.select2-dropdown {
  background-color: var(--lightGrey);
  border-color: var(--Grey);
}

:where(input, textarea).form-control {
  background-color: var(--Grey) !important;
  border: none !important;
  border-radius: 0.15rem !important;
  color: var(--textColor);
  font-size: 0.8rem !important;
  margin: 5px 0;
  padding: 0.75rem !important;
}

:where(input, textarea).form-control:focus {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8ae;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {

  background: #A8A8A8;
}

.rtl .tel {
  direction: ltr;
  justify-content: end;
}

.page-content {
  position: relative;
  transition: 0.3s;
  padding: 1.25rem;
}

/* .side-bar~.page-content {
  margin-left: 75px;
}

.side-bar.expanded~.page-content {
  margin-left: 200px;
} */

.add-case.show~.page-content,
.add-case.show~footer {
  margin-right: 300px;
}

.rtl .add-case.show~.page-content,
.rtl .add-case.show~footer {
  margin-right: 0;
  margin-left: 300px;
}


.card {
  width: 100%;
  box-shadow: 0 0 15px -5px #e5e5e5;
  border-color: #efefef !important;
  padding: 1.5rem 1rem;
}

.gm-style button {
  top: 0 !important;
  right: 0 !important;
}

.filter .date-picker {
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 0;
  background-color: #292E30;
}

.filter .date-picker::placeholder {
  color: white;
  font-family: 'Aldrich', sans-serif;
  opacity: 1;
  font-size: 1rem;
}

.rtl .filter .date-picker::placeholder {
  font-family: 'Noto Kufi Arabic', sans-serif;
}

.filter .dp__theme_dark {
  --dp-primary-color: var(--mainColor) !important;
}

.filter .dp__preset_range {
  padding: 0.75rem 1.25rem;
}

.date-picker::placeholder {
  font-size: 18px;
  color: white;
}

.rtl .dp__month_year_row {
  direction: ltr;
}

.dp__cell_inner {
  border-radius: 0 !important;
}

.rtl .dp__range_start,
.dp__range_end,
.dp__date_hover_end:not(.dp__range_between) {
  border-radius: 0 4px 4px 0 !important;
}

.rtl .dp__range_end,
.rtl .dp__date_hover_end:not(.dp__range_between),
.dp__range_start {
  border-radius: 4px 0 0 4px !important;
}

.rtl .dp__clear_icon {
  right: unset;
  left: 0;
}

.rtl .dp__date_hover_end .dp__month_year_row {
  direction: ltr !important;
}

.rtl .gm-style .gm-style-iw-c {
  padding: 0.5rem 1rem 0 0 !important;
}

.rtl .gm-style .gm-style-iw-d {
  padding: 0 !important;
}

.rtl .gm-style button {
  right: unset !important;
  left: 0 !important;
}

.slider-connect {
  background: var(--mainColor) !important;
}

.slider-txt-dir-rtl.slider-horizontal .slider-handle {
  left: unset !important;
  right: calc(var(--slider-handle-width, 16px)/2*-1) !important;
}

.slider-txt-dir-rtl.slider-horizontal .slider-handle:focus {
  box-shadow: none;
}

.slider-txt-dir-rtl.slider-horizontal .slider-origin>.slider-tooltip-top {
  right: 0% !important;
  background: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}
</style>
