<template>
    <div class="add-case">
        <form @submit="addEvent">
            <h5 class="mb-3">{{ $t('addCase.info') }}</h5>
            <Select2 id="countries-select" v-model="selectedCountry"
                :settings="{ dir: ($i18n.locale == 'ar') ? 'rtl' : 'ltr' }" :options="countries"
                @select="fetchStates(selectedCountry)" :placeholder="$t('addCase.country')" />
            <Select2 v-model="selectedState" :settings="{ dir: ($i18n.locale == 'ar') ? 'rtl' : 'ltr' }" :options="states"
                @select="fetchCities(selectedState)" :placeholder="$t('addCase.state')" />
            <Select2 v-model="selectedCity" :settings="{ dir: ($i18n.locale == 'ar') ? 'rtl' : 'ltr' }" :options="cities"
                :placeholder="$t('addCase.city')" />
            <textarea v-model="description" name="" id="" cols="30" rows="5" class="form-control"
                :placeholder="$t('addCase.desc')"></textarea>
            <hr>
            <h5 class="mb-3">{{ $t('contact-info') }}</h5>
            <input v-model="name" type="text" class="form-control" :placeholder="$t('full-name')">
            <input v-model="phone" type="text" class="form-control" :placeholder="$t('phone')">
            <input v-model="email" type="text" class="form-control" :placeholder="$t('email')">
            <button class="btn btn-main mt-4 w-100">{{ $t('send-review') }}</button>
        </form>
    </div>
</template>

<script>

import Button from "./Button.vue";
import Select2 from 'vue3-select2-component';

export default {
    components: {
        Button,
        Select2
    },
    data() {
        return {
            countries: [],
            states: [],
            cities: [],
            selectedCountry: '',
            selectedState: '',
            selectedCity: '',
            description: '',
            name: '',
            phone: '',
            email: '',
        }
    },
    methods: {
        async fetchCountries() {
            const res = await fetch('https://backend.freedomstech.net/api/get_countries')
            const countries = await res.json()
            const select2Countries = countries.data.map((country) => ({ id: country.id, text: country.name }))
            return select2Countries
        },
        async fetchStates(countryID) {
            let params = new URLSearchParams();
            params.append('country_id', countryID);

            const res = await fetch('https://backend.freedomstech.net/api/get_states?' + params)
            const states = await res.json()
            this.states = states.data.map((state) => ({ id: state.id, text: state.name }))

            if (this.states.length == 1) {
                this.selectedState = this.states[0].id
                this.fetchCities(this.selectedState)
            }
        },
        async fetchCities(stateID) {
            let params = new URLSearchParams();
            params.append('state_id', stateID);

            const res = await fetch('https://backend.freedomstech.net/api/get_cities?' + params)
            const cities = await res.json()
            this.cities = cities.data.map((city) => ({ id: city.id, text: city.name }))

            if (this.cities.length == 1) {
                this.selectedCity = this.cities[0].id
            }
        },
        onSubmit() {
            const newEvent = {
                contact_name: this.name,
                country_code: '',
                phone: this.phone,
                email: this.email,
                country_id: this.selectedCountry,
                state_id: this.selectedState,
                city_id: this.selectedCity,
                description: this.description,
            }

            this.name = '',
                this.code = '',
                this.phone = '',
                this.email = '',
                this.selectedState = '',
                this.selectedCity = '',
                this.description = ''

            return newEvent
        },
        async addEvent(e) {
            e.preventDefault()
            const newEvent = await this.onSubmit()
            const res = await fetch('https://backend.freedomstech.net/api/submit_case', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(newEvent)
            })
            const data = await res.json()
            this.events = [...this.events, data]
            console.log(this.events)
        }
    },
    async created() {
        this.countries = await this.fetchCountries()
        if (this.countries.length == 1) {
            this.selectedCountry = this.countries[0].id
            this.fetchStates(this.selectedCountry)
        }
    }
}
</script>

<style scoped>
.add-case {
    position: fixed;
    right: 0;
    height: calc(100vh - 75px);
    background-color: white;
    padding: 1rem 0rem;
    width: 0px;
    transition: 0.3s;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
}

.rtl .add-case {
    right: unset;
    left: 0;
}

.add-case * {
    white-space: nowrap;
}

.add-case.show {
    background-color: white;
    padding: 1rem;
    width: 300px;
    box-shadow: 0 0 16px -10px var(--darkGrey);
}

h5 {
    font-weight: bold;
    color: var(--mainColor);
}

.add-case textarea {
    white-space: normal;
}
</style>