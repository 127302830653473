<template>
    <div class="filter">
        <form action="">
            <div class="accordion" id="filterAccordion">
                <div class="card">
                    <div id="headingGender">
                        <button class="accordion-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseGender" aria-expanded="true" aria-controls="collapseGender">
                            {{ $t("filter.gender") }}
                        </button>
                    </div>
                    <div id="collapseGender" class="collapse show" aria-labelledby="headingGender"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="genders">
                                <li v-for="gender in genders" :key="gender.id" :id="gender.id" class="gender"
                                    :class="{ 'checked': selectedGenders.includes(gender.id) }"
                                    @click="checkGender(gender.id)"><span>{{ gender.title }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingCountry">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseCountry" aria-expanded="false" aria-controls="collapseCountry">
                            {{ $t("filter.state") }}
                        </button>
                    </div>
                    <div id="collapseCountry" class="collapse" aria-labelledby="headingCountry"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="countries">
                                <li v-for="state in states" :key="state.id" :id="state.id" class="state"
                                    :class="{ 'checked': selectedStates.includes(state.id) }" @click="checkState(state.id)">
                                    <span>{{ state.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingCovenant">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseCovenant" aria-expanded="false" aria-controls="collapseCovenant">
                            {{ $t("filter.covenant") }}
                        </button>
                    </div>
                    <div id="collapseCovenant" class="collapse" aria-labelledby="headingCovenant"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="covenants">
                                <li v-for="covenant in covenants " :key="covenant.id" :id="covenant.id" class="covenant"
                                    :class="{ 'checked': selectedCovenants.includes(covenant.id) }"
                                    @click="checkCovenant(covenant.id)"><span>{{ covenant.title }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingAge">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseAge" aria-expanded="false" aria-controls="collapseAge">
                            {{ $t("filter.age") }}
                        </button>
                    </div>
                    <div id="collapseAge" class="collapse" aria-labelledby="headingAge" data-bs-parent="#filterAccordion">
                        <div class="card-body slider-body">
                            <Slider v-model="slider.value" v-bind="slider"></Slider>
                        </div>
                    </div>
                </div>
                <VueDatePicker v-model="date" :locale="($i18n.locale == 'ar') ? 'ar' : 'en'"
                    :placeholder="$t('filter.date')" auto-apply range :preset-ranges="presetRanges" week-start="0" dark
                    :enable-time-picker="false" hide-input-icon input-class-name="date-picker"
                    menu-class-name="date-picker-menu" calendar-cell-class-name="date-picker-cell"
                    calendar-class-name="date-pocker-calendar">
                    <span @click="presetDateRange(range)">{{ $t('label') }}</span>
                </VueDatePicker>
            </div>
            <button @click="filter" type="button" class="accordion-btn justify-content-center mt-2">{{ $t("filter.apply")
            }}</button>
        </form>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import Slider from '@vueform/slider';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, endOfWeek, startOfWeek, subMonths } from 'date-fns';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "Filter",
    components: {
        VueDatePicker,
        onMounted,
        Slider,
    },
    setup() {
        // Date picker
        const date = ref();
        const presetRanges = ref([
            { label: 'Today', range: [new Date(), new Date()] },
            { label: 'This Week', range: [startOfWeek(new Date()), endOfWeek(new Date())] },
            { label: 'This Month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
            { label: 'Last Month', range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))], },
            { label: 'This Year', range: [startOfYear(new Date()), endOfYear(new Date())] },
        ]);
        onMounted(() => {
            // const startDate = new Date();
            // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
            // date.value = [startDate, endDate];
        })
        return { date, presetRanges }
    },
    data() {
        return {
            states: [],
            genders: [],
            covenants: [],
            ages: [],
            selectedStates: [],
            selectedGenders: [],
            selectedCovenants: [],
            selectedAges: [],
            slider: {
                value: [0, 0],
                merge: 5,
                direction: "ltr"
            },
            rtl: localStorage.getItem('lang') == 'ar'
        }
    },
    methods: {
        filterCheck(array, value) {
            if (array.includes(value)) {
                array = array.filter(function (filtered) {
                    return filtered !== value;
                });
            } else {
                array.push(value);
            };
            return array
        },
        checkGender(genderID) {
            this.selectedGenders = this.filterCheck(this.selectedGenders, genderID);
        },
        checkState(stateID) {
            this.selectedStates = this.filterCheck(this.selectedStates, stateID);
        },
        checkCovenant(covenantID) {
            this.selectedCovenants = this.filterCheck(this.selectedCovenants, covenantID);
        },
        checkAge(ageID) {
            this.selectedAges = this.filterCheck(this.selectedAges, ageID);
        },

        // Fetching data from API
        async fetchGenders() {
            const res = await fetch('https://backend.freedomstech.net/api/get_genders?lang_id=2')
            const genders = await res.json()
            return genders.data
        },
        async fetchStates() {
            const res = await fetch('https://backend.freedomstech.net/api/get_states?lang_id=2&country_id=65')
            const states = await res.json()
            return states.data
        },
        async fetchCovenants() {
            const res = await fetch('https://backend.freedomstech.net/api/get_covenants?lang_id=2')
            const covenants = await res.json()
            return covenants.data
        },
        async fetchAges() {
            const res = await fetch('https://backend.freedomstech.net/api/helpers/get_age_range?lang_id=2')
            const ages = await res.json()
            return ages
        },

        // Send data to Parent
        filter() {
            this.$emit('filtered', this.selectedGenders, this.selectedStates, this.selectedCovenants, this.date, this.slider.value)
        }
    },
    async created() {
        this.genders = await this.fetchGenders()
        this.states = await this.fetchStates()
        this.covenants = await this.fetchCovenants()
        this.ages = await this.fetchAges().then(ages => {
            this.slider.value = [ages[0].min_age, ages[0].max_age]
            this.slider.min = ages[0].min_age
            this.slider.max = ages[0].max_age
        })
        this.slider.direction = this.rtl ? 'rtl' : 'ltr'
    }
}

</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.filter {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 0px;
    transition: 0.3s;
    z-index: 50;
    width: 260px;
    max-height: 100%;
}

.rtl .filter {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 0px;
    transition: 0.3s;
    z-index: 50;
    width: 260px;
    max-height: 100%;
}

.filter * {
    white-space: nowrap;
}

.filter .card-body {
    padding: 0;
}

.filter .card-body.slider-body {
    padding: 1.5rem;
}

.filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #23282b10;
    max-height: 200px;
    overflow-x: visible;
    overflow-y: auto;
}

.filter ul li {
    text-align: left;
    border-bottom: 1px solid #ffffff0a;
    cursor: pointer;

}

.filter ul li span {
    display: block;
    padding: .75rem 1.5rem;
    opacity: 0.5;
    transition: 0.3s;
}

.filter ul li.checked span {
    padding: .75rem 2rem;
    opacity: 1;
    color: white;
}

.filter .accordion {
    border-radius: 0;
    box-shadow: 0 0 20px 0 #222;

}

.filter .accordion .card {
    border: none;
    border-radius: 0;
    background-color: #23282b;
    box-shadow: none;
    padding: 0;
}

.filter .accordion-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    color: var(--lightGrey);
    background-color: #292e30 !important;
    color: white !important;
    border-radius: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #0003 !important;
}
</style>